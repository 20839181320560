import { Ref, ref } from 'vue';
import { WorkspaceUsageSituation } from '@/api/openapi';
import { WorkspaceApi } from '@/api';

export const usePlan = (): {
  fetchPlan: (workspaceId: string) => void;
  plan: Ref<WorkspaceUsageSituation>;
} => {
  const plan = ref();

  return {
    plan,
    fetchPlan: async workspaceId => {
      const response = await WorkspaceApi.getWorkspacesWorkspaceIdUsageSituation(
        Number(workspaceId)
      );
      if (!response.data) return;
      plan.value = response.data;
    }
  };
};
