import { AclRule } from '@/plugins/acl';
import { PRIVILEGE } from '@/common/constant';

export const workspaceChangeNameAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.freeOwner);

export const workspaceDropAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.freeOwner);

export const workspaceChangeOwnerAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.admin);

export const workspaceManageMailDomainAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.admin);

// 自動承認の切り替え
export const workspaceSetDomainApprovalAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.admin);

export const workspaceApplyFeeChargingPlanAcl = new AclRule(
  PRIVILEGE.freeOwner
);

export const workspaceOptionAcl = new AclRule(PRIVILEGE.primary).or(
  PRIVILEGE.owner
);

export const workspaceOptionSetAccountAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.admin);

export const workspaceManageAccountAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.freeOwner)
  .or(PRIVILEGE.admin);

export const workspaceManageOwnerAuthorityAcl = new AclRule(PRIVILEGE.primary);

export const workspaceAttachAdminAuthorityAcl = new AclRule(
  PRIVILEGE.primary
).or(PRIVILEGE.owner);

export const workspaceSwitchRestrictedMemberAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.admin);

export const workspaceAddCompanyAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.admin);

export const workspaceDeleteCompanyAcl = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.admin);

export const workspaceAttachCompanyAuthority = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.admin);

export const companyChangeTargetCM = new AclRule(PRIVILEGE.freeOwner);

export const companyChangeName = new AclRule(PRIVILEGE.primary)
  .or(PRIVILEGE.owner)
  .or(PRIVILEGE.freeOwner)
  .or(PRIVILEGE.admin);
