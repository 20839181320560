<template>
  <Card dense>
    <div
      v-for="(company, index) in store.workspaceCompanies.list"
      :key="company.id"
      :class="{ 'mt-6': index !== 0 }"
    >
      <Flex justify-space-between align-center>
        <router-link
          :to="{
            name: COMPANY_ROUTES.top,
            params: {
              companyId: company.id,
              workspaceId
            }
          }"
        >
          <TextLink>
            <TypographyEllipsis
              :title="company?.name"
              width="100%"
              line-height="1.5"
              bold
              class="mr-2"
              color="inherit"
            >
              {{ company?.name }}
            </TypographyEllipsis>
          </TextLink>
        </router-link>
        <router-link
          :to="{
            name: SETTINGS_ROUTES.brand,
            params: {
              companyId: company.id,
              workspaceId
            }
          }"
        >
          <Button color="white">設定</Button>
        </router-link>
      </Flex>
      <Flex v-if="company?.creator" class="mt-4">
        <Box width="64px">作成者</Box>
        <Box flex="1">{{ company?.creator?.name }}</Box>
        <AccountIcon
          :imageUrl="company?.creator.imageUrl"
          :privilege="company?.creator.accountPrivilege"
          size="xs"
        />
      </Flex>
      <Flex align-end class="mt-4">
        <Typography bold>
          <span v-digit3>{{ company?.accountIds.length }}</span>
        </Typography>
        <Typography class="ml-1">users</Typography>
      </Flex>
    </div>
  </Card>
</template>
<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { COMPANY_ROUTES, SETTINGS_ROUTES } from '@/router';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import AccountIcon from '@/components/ui/AccountIcon.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import Typography from '@/components/ui/Typography.vue';
import TextLink from '@/components/ui/TextLink.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';
import { useWorkspaceStore } from '@/store/workspace';

export default defineComponent({
  name: 'Company',
  components: {
    AccountIcon,
    Button,
    Box,
    Card,
    Flex,
    Typography,
    TextLink,
    TypographyEllipsis
  },
  setup() {
    const {
      params: { workspaceId }
    } = useRoute();

    const store = useWorkspaceStore();
    store.setWorkspaceCompanyUsers(workspaceId.toString());

    return {
      store,
      COMPANY_ROUTES,
      SETTINGS_ROUTES,
      workspaceId
    };
  }
});
</script>
