import { WorkspaceApi } from '@/api';
import {
  ChangeWorkspaceDomainFormApproveTypeEnum,
  CreateWorkspaceDomainForm,
  WorkspaceDomain,
  WorkspaceDomainApplicationStatusEnum,
  WorkspaceDomainApproveTypeEnum,
  WorkspaceDomainDefinitionTypeEnum,
  WorkspaceDomainList
} from '@/api/openapi/api';
import { VALIDATION_MESSAGE } from '@/common/validation';
import { toast } from '@/components/ui/Toast/toast';
import useLoading from '@/composables/loading';
import { computed, ComputedRef, Ref, ref } from 'vue';

export const useDomains = (
  workspaceId: string
): {
  domains: Ref<WorkspaceDomain[] | undefined>;
  fetchDomains: (workspaceId: string) => void;
  isCreateLoading: Ref<boolean>;
  isUpdateLoading: Ref<boolean>;
  postDomain: (
    userId: number,
    domainId: number,
    newDomainName: string,
    cbOnSuccess?: () => void
  ) => void;
  patchApproval: (domainId: number, isApproval: boolean) => void;
  updateDomain: ({
    domainId,
    domainName
  }: {
    domainId: number;
    domainName: string;
  }) => Promise<void>;
  createDomain: (domainName: string) => Promise<void>;
} => {
  const domains = ref<WorkspaceDomain[]>();
  const _createDomain = async (domainName: string) => {
    const params = {
      domainName,
      approveType: 'AUTOMATIC'
    } as CreateWorkspaceDomainForm;
    const response = await WorkspaceApi.postWorkspacesWorkspaceIdWorkspaceDomains(
      Number(workspaceId),
      params
    );
    if (!(200 <= response.status && response.status < 300)) {
      throw new Error();
    }
  };

  const _updateDomain = async ({
    domainId,
    domainName
  }: {
    domainId: number;
    domainName: string;
  }) => {
    const response = await WorkspaceApi.putWorkspaceDomainsWorkspaceDomainIdEdit(
      domainId,
      {
        domainName
      }
    );
    if (!(200 <= response.status && response.status < 300)) {
      throw new Error();
    }
  };

  const [isCreateLoading, createDomain] = useLoading(_createDomain);
  const [isUpdateLoading, updateDomain] = useLoading(_updateDomain);

  return {
    domains,
    isCreateLoading,
    isUpdateLoading,
    fetchDomains: async workspaceId => {
      const response = await WorkspaceApi.getWorkspacesWorkspaceIdWorkspaceDomains(
        Number(workspaceId)
      );
      if (!response.data) return;
      domains.value = response.data.list;
    },
    postDomain: async (userId, domainId, newDomainName, cbOnSuccess) => {
      const domain = domains.value?.find(domain => domain.id === domainId);
      if (!domain || !domain.domainName) {
        throw new Error();
      }
      const res = await WorkspaceApi.postWorkspacesWorkspaceIdContractDomainApplications(
        Number(workspaceId),
        {
          submitAccountId: userId,
          oldDomainName: domain.domainName,
          newDomainName
        }
      );
      if (200 <= res.status && res.status < 300) {
        toast({
          title: '完了',
          message: '契約ドメインの変更申請を送りました',
          variant: 'success'
        });
        if (cbOnSuccess) {
          cbOnSuccess();
        }
      } else {
        toast({
          title: '失敗',
          message: '契約ドメインの変更申請に失敗しました',
          variant: 'error'
        });
      }
    },
    patchApproval: (domainId, isApproval) => {
      WorkspaceApi.putWorkspaceDomainsWorkspaceDomainIdEdit(domainId, {
        approveType:
          ChangeWorkspaceDomainFormApproveTypeEnum[
            isApproval ? 'Manual' : 'Automatic'
          ]
      });
    },
    updateDomain,
    createDomain
  };
};

type Domain = {
  id?: number;
  domainName?: string;
  oldDomainName?: string;
  isApproval: boolean;
  isDomainSubmitted?: boolean;
};

export const useDomainForm = (): {
  contract: Ref<Domain | undefined>;
  domainList: Ref<Domain[] | undefined>;
  newDomainIds: Ref<number[]>;
  setDomainForm: (domains: WorkspaceDomainList['list']) => void;
  addDomain: () => void;
  removeDomainList: (domainId: number) => void;
  deleteDomain: (domainId: number) => Promise<void>;
  removeNewDomainId: (domainId: number) => void;
} => {
  const contract = ref<Domain>();
  const domainList = ref<Domain[]>();
  const newDomainIds = ref<number[]>([]);

  return {
    contract,
    domainList,
    newDomainIds,
    setDomainForm: domains => {
      const contractData = domains?.find(
        domain =>
          domain.definitionType === WorkspaceDomainDefinitionTypeEnum.Contract
      );
      contract.value = {
        id: contractData?.id,
        domainName: contractData?.domainName,
        oldDomainName: contractData?.domainName,
        isApproval:
          contractData?.approveType === WorkspaceDomainApproveTypeEnum.Manual,
        isDomainSubmitted:
          contractData?.applicationStatus ===
          WorkspaceDomainApplicationStatusEnum.Submitted
      };
      domainList.value = domains
        ?.filter(
          domain =>
            domain.definitionType ===
            WorkspaceDomainDefinitionTypeEnum.Preference
        )
        .map(domain => ({
          id: domain.id,
          domainName: domain.domainName,
          oldDomainName: domain.domainName,
          isApproval:
            domain.approveType === WorkspaceDomainApproveTypeEnum.Manual
        }));
    },
    addDomain: () => {
      let newId = domainList.value?.length || 1;
      while (domainList.value?.some(domain => domain.id === newId)) {
        newId++;
      }
      newDomainIds.value = [...newDomainIds.value, newId];
      const newDomain = {
        id: newId,
        domainName: '',
        isApproval: false
      };

      domainList.value = domainList.value
        ? [...domainList.value, newDomain]
        : [newDomain];
    },
    removeDomainList: async domainId => {
      domainList.value = domainList.value?.filter(
        domain => domain.id !== domainId
      );
      newDomainIds.value = newDomainIds.value.filter(id => id !== domainId);
    },
    deleteDomain: async domainId => {
      const response = await WorkspaceApi.deleteWorkspaceDomainsWorkspaceDomainIdDelete(
        domainId
      );
      if (!(200 <= response.status && response.status < 300)) {
        throw new Error();
      }
    },
    removeNewDomainId: domainId =>
      (newDomainIds.value = newDomainIds.value.filter(id => domainId !== id))
  };
};

type DomainErrorMessages = {
  id: number;
  message: string;
}[];

export const useValidateDomain = (): {
  errorMessageIds: ComputedRef<number[]>;
  domainErrorMessages: Ref<DomainErrorMessages>;
  validate: (
    domainName: string,
    allowDomainList: Domain[] | undefined,
    domainId: number
  ) => boolean;
} => {
  const errorMessageType = {
    FORMAT: 'FORMAT',
    DUPULICATE: 'DUPULICATE'
  } as const;
  const errorMessageIds: ComputedRef<number[]> = computed(() =>
    domainErrorMessages.value.map(error => error.id)
  );
  const domainErrorMessages = ref<DomainErrorMessages>([]);
  const inValidDomainFormat = (domainName: string): boolean =>
    domainName.match(
      /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
    ) === null;
  const getErrorMessage = (
    type: typeof errorMessageType[keyof typeof errorMessageType]
  ): string => {
    if (type === errorMessageType.FORMAT) {
      return VALIDATION_MESSAGE.domain;
    }
    if (type === errorMessageType.DUPULICATE) {
      return VALIDATION_MESSAGE.domainDuplicate;
    }

    return '';
  };

  return {
    errorMessageIds,
    domainErrorMessages,
    validate: (domainName, domainList, domainId) => {
      let errorMessage = '';

      if (inValidDomainFormat(domainName)) {
        errorMessage = getErrorMessage(errorMessageType.FORMAT);
      } else if (
        domainList?.find(domain => domain.oldDomainName === domainName)
      ) {
        errorMessage = getErrorMessage(errorMessageType.DUPULICATE);
      }

      domainErrorMessages.value = domainErrorMessages.value.filter(
        domainErrorMessage => domainErrorMessage.id !== domainId
      );

      const hasError = Boolean(
        errorMessage &&
          !domainErrorMessages.value.map(error => error.id).includes(domainId)
      );
      if (hasError) {
        domainErrorMessages.value = [
          ...domainErrorMessages.value,
          {
            id: domainId,
            message: errorMessage
          }
        ];
      }
      return hasError;
    }
  };
};
