<template>
  <div>
    <Flex justify-space-between>
      <Box flex="1">
        <Plan :paymentType="workspace?.paymentType" />
        <Domain class="mt-6" />
      </Box>
      <Box width="278px" class="ml-6">
        <Company />
      </Box>
    </Flex>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Plan from '@/pages/workspace/Plan.vue';
import Company from '@/pages/workspace/Company.vue';
import Domain from '@/pages/workspace/Domain.vue';

import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';

export default defineComponent({
  name: 'WorkspaceTop',
  components: {
    Box,
    Company,
    Domain,
    Flex,
    Plan
  },
  props: {
    workspace: Object
  }
});
</script>
