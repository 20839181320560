<template>
  <Card v-if="plan?.paymentType" dense>
    <template v-if="plan?.paymentType === PAYMENT_TYPE.paid">
      <Flex justify-end align-center>
        <Flex align-center>
          <Flex align-center>
            <Icon name="users" class="mr-1" />
            <Typography size="s" class="mr-2">正規ユーザー数</Typography>
            <div
              class="count-card"
              :class="{ 'no-limit': isUseLicenseMaxLimit }"
            >
              <template v-if="isUseLicenseMaxLimit">
                <div class="unlimited-label">
                  <Typography size="xs" color="grey">無制限</Typography>
                </div>
              </template>
              <Typography
                bold
                size="l"
                :color="
                  plan?.userLicenses.limit === plan?.userLicenses.inUse
                    ? 'secondary'
                    : 'inherit'
                "
              >
                {{ plan?.userLicenses.inUse }}
              </Typography>
              <Typography v-if="!isUseLicenseMaxLimit">
                /{{ plan?.userLicenses.limit }}
              </Typography>
            </div>
          </Flex>
          <Flex align-center class="ml-8">
            <Icon name="company" class="mr-1" />
            <Typography size="s" class="mr-2">カンパニー数</Typography>
            <div
              class="count-card"
              :class="{ 'no-limit': isCompanyLicenseMaxLimit }"
            >
              <template v-if="isCompanyLicenseMaxLimit">
                <div class="unlimited-label">
                  <Typography size="xs" color="grey">無制限</Typography>
                </div>
              </template>
              <Flex justify-end align-end>
                <Typography
                  bold
                  size="l"
                  :color="
                    plan?.companyLicenses.limit === plan?.companyLicenses.inUse
                      ? 'secondary'
                      : 'inherit'
                  "
                >
                  {{ plan?.companyLicenses.inUse }}
                </Typography>
                <Typography v-if="!isCompanyLicenseMaxLimit">
                  /{{ plan?.companyLicenses.limit }}
                </Typography>
              </Flex>
            </div>
          </Flex>
        </Flex>
      </Flex>
    </template>
    <template v-if="plan?.paymentType === PAYMENT_TYPE.free">
      <Flex justify-space-between align-center>
        <Box flex="1" class="ml-2">
          <Typography bold line-height="1.5">
            無料プランでは一部の機能が制限されています。
          </Typography>
          <Typography line-height="1.5">
            <TextLink>
              <a @click="openChatBot">アップグレード</a>
            </TextLink>
            することですべての機能をお使いいただけます。
          </Typography>
        </Box>
      </Flex>
    </template>
  </Card>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { usePlan } from '@/composables/plan';
import { PAYMENT_TYPE } from '@/common/constant';

import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Card from '@/components/ui/Card.vue';
import Icon from '@/components/ui/Icon.vue';
import TextLink from '@/components/ui/TextLink.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'Plan',
  components: {
    Card,
    Box,
    Flex,
    Icon,
    TextLink,
    Typography
  },
  setup() {
    // チャットボットの起動
    const openChatBot = () => {
      window.ChannelIO('showMessenger');
    };

    const {
      params: { workspaceId }
    } = useRoute();

    const { plan, fetchPlan } = usePlan();
    fetchPlan(workspaceId);

    const isUseLicenseMaxLimit = computed(() => {
      return plan.value?.userLicenses.limit >= 999;
    });

    const isCompanyLicenseMaxLimit = computed(() => {
      return plan.value?.companyLicenses.limit >= 99;
    });

    return {
      plan,
      PAYMENT_TYPE,
      isUseLicenseMaxLimit,
      isCompanyLicenseMaxLimit,
      openChatBot
    };
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/spacing';

.count-card {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  background-color: var(--dark-5-color);
  border-radius: 4px;
  padding: $spacing-base * 2;
  width: 100px;

  &.no-limit {
    width: 110px;
    justify-content: space-between;
  }
}

.unlimited-label {
  background-color: var(--contrast-color);
  border-radius: 4px;
  padding: $spacing-base;
}

.link {
  cursor: pointer;
}
</style>
