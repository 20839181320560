<template>
  <div>
    <Card
      v-if="contract"
      title="契約ドメイン"
      :card-content-style="{ paddingBottom: '6px' }"
    >
      <Flex justify-space-between>
        <!-- v1.0 スコープ外 -->
        <!-- <Switch
          v-model="contract.isApproval"
          :disabled="
            contract.isDomainSubmitted ||
              !acl.can(workspaceSetDomainApprovalAcl)
          "
          label="承認制"
          class="approval-switch"
          @update:model-value="toggleApproval(contract.id, contract.isApproval)"
        /> -->
        <Box flex="1">
          <TextField
            v-model="contract.domainName"
            :disabled="
              contract.isDomainSubmitted || !acl.can(AclRules.chargedPrivilege)
            "
            :error-message="
              errorMessageIds.includes(contract.id)
                ? domainErrorMessages.find(
                    domainErrorMessage => domainErrorMessage.id === contract.id
                  ).message
                : ''
            "
            @input="validate(contract.domainName, domainList, contract.id)"
          />
        </Box>
        <Modal
          v-model="isContractModalOpen"
          title="契約ドメインの変更申請を行います"
          button-label="OK"
          @click-button="applyDomain"
          @close="onClose"
        >
          <template v-slot:activator="{ on }">
            <Button
              size="s"
              :disabled="
                contract.isDomainSubmitted ||
                  errorMessageIds.includes(contract.id) ||
                  contract.domainName === contract.oldDomainName ||
                  !acl.can(AclRules.chargedPrivilege)
              "
              @click="on"
              class="ml-3"
            >
              {{ contract.isDomainSubmitted ? '変更申請中' : '変更申請' }}
            </Button>
          </template>
          <Box width="352px" text-align="center">
            <Typography line-height="1.5" class="mt-4">
              変更申請を取り消したい場合は<br />担当営業までご連絡ください
            </Typography>
          </Box>
        </Modal>
      </Flex>
    </Card>
    <Card title="許可されているドメイン" class="mt-6">
      <Typography line-height="1.5" class="mb-4">
        以下のメールドメインでアカウントを作成したユーザーはこのワークスペースに参加します
      </Typography>
      <div v-for="domain in domainList" :key="domain.id" class="mt-2">
        <Flex justify-space-between>
          <!-- v1.0 スコープ外 -->
          <!-- <Switch
            v-model="domain.isApproval"
            :disabled="
              workspace.paymentType === PAYMENT_TYPE.free ||
                newDomainIds.includes(domain.id) ||
                !acl.can(workspaceSetDomainApprovalAcl)
            "
            label="承認制"
            class="approval-switch"
            @update:model-value="toggleApproval(domain.id, domain.isApproval)"
          /> -->
          <Box flex="1">
            <TextField
              v-model="domain.domainName"
              :disabled="
                !acl.can(AclRules.chargedPrivilege) &&
                  !acl.can(workspaceApplyFeeChargingPlanAcl)
              "
              :error-message="
                errorMessageIds.includes(domain.id)
                  ? domainErrorMessages.find(
                      domainErrorMessage => domainErrorMessage.id === domain.id
                    ).message
                  : ''
              "
              placeholder="xxx.co.jp"
              @input="
                validate(
                  domain.domainName,
                  [...domainList.filter(d => d.id !== domain.id), contract],
                  domain.id
                )
              "
            />
          </Box>
          <Button
            size="s"
            v-if="
              acl.can(AclRules.chargedPrivilege) ||
                acl.can(workspaceApplyFeeChargingPlanAcl)
            "
            class="ml-3"
            height="fit-content"
            :disabled="
              errorMessageIds.includes(domain.id) ||
                domain.domainName === '' ||
                (!acl.can(workspaceManageMailDomainAcl) &&
                  !acl.can(workspaceApplyFeeChargingPlanAcl))
            "
            :loading="
              currentEditedDomainIds.includes(domain.id) && isAddedDomainLoading
            "
            @click="
              changeDomain(domain.id, domain.domainName, domain.oldDomainName)
            "
          >
            設定
          </Button>
          <Box class="ml-2" padding-top="10px">
            <IconButton
              v-if="domainList.length >= 1"
              icon-name="close-circle"
              :disabled="
                !acl.can(AclRules.chargedPrivilege) &&
                  !acl.can(workspaceApplyFeeChargingPlanAcl)
              "
              @click="
                () => {
                  if (!newDomainIds.includes(domain.id)) {
                    removeDomain = domain;
                    openRemoveDomainModal();
                  } else {
                    removeDomainList(domain.id);
                  }
                }
              "
            />
          </Box>
        </Flex>
      </div>
      <Button
        v-if="
          acl.can(AclRules.chargedPrivilege) ||
            acl.can(workspaceApplyFeeChargingPlanAcl)
        "
        block
        class="mt-6"
        icon-name="plus"
        icon-sides="16"
        icon-color="primary"
        color="white"
        variant="outlined"
        @click="addDomain"
      >
        追加する
      </Button>
    </Card>
    <RemoveModal
      v-model="isRemoveDomainModal"
      title="許可ドメインの削除"
      :target="removeDomain?.domainName"
      @remove="
        async () => {
          closeRemoveDomainModal();
          isPageLoading = true;
          try {
            await deleteDomain(removeDomain?.id);
            removeDomainList(removeDomain?.id);
            toast({
              title: '成功',
              message: `ドメインを削除しました`,
              variant: 'success'
            });
          } catch {
            toast({
              title: '失敗',
              message: `ドメインの削除に失敗しました`,
              variant: 'error'
            });
          } finally {
            isPageLoading = false;
          }
        }
      "
    />
    <PageLoading v-if="isPageLoading" />
  </div>
</template>
<script>
import { defineComponent, watch, ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useUserInfoStore } from '@/store/userInfo';
import { useWorkspaceStore } from '@/store/workspace';
import {
  useDomains,
  useDomainForm,
  useValidateDomain
} from '@/composables/domain';
import { PAYMENT_TYPE } from '@/common/constant';
import { VALIDATION_MESSAGE } from '@/common/validation';
import useModal from '@/composables/modal';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Button from '@/components/ui/Button.vue';
import Card from '@/components/ui/Card.vue';
import IconButton from '@/components/ui/IconButton.vue';
import Modal from '@/components/ui/Modal.vue';
import PageLoading from '@/components/ui/PageLoading.vue';
import RemoveModal from '@/components/ui/RemoveModal.vue';
import TextField from '@/components/ui/TextField.vue';
import Typography from '@/components/ui/Typography.vue';
import { AclRules } from '@/plugins/acl';
import { useAcl } from '@/store/acl';
import {
  workspaceSetDomainApprovalAcl,
  workspaceManageMailDomainAcl,
  workspaceApplyFeeChargingPlanAcl
} from '@/composables/acls';
import { errorToast, successToast } from '@/components/ui/Toast/toast';
import { toast } from '@/components/ui/Toast/toast';

export default defineComponent({
  name: 'Domain',
  components: {
    Box,
    Button,
    Card,
    Flex,
    IconButton,
    Modal,
    PageLoading,
    RemoveModal,
    TextField,
    Typography
  },
  setup() {
    const {
      params: { workspaceId }
    } = useRoute();
    const store = useWorkspaceStore();
    const acl = useAcl();
    const { workspace } = storeToRefs(store);
    const userInfoStore = useUserInfoStore();
    const { userInfo } = storeToRefs(userInfoStore);
    const {
      domains,
      fetchDomains,
      postDomain,
      // [承認切り替え] v1.0 スコープ外
      // patchApproval,
      updateDomain,
      createDomain,
      isCreateLoading,
      isUpdateLoading
    } = useDomains(workspaceId);

    fetchDomains(workspaceId);

    const {
      contract,
      domainList,
      newDomainIds,
      addDomain,
      removeDomainList,
      deleteDomain,
      setDomainForm,
      removeNewDomainId
    } = useDomainForm();

    watch(domains, () => setDomainForm(domains.value));

    const {
      errorMessageIds,
      domainErrorMessages,
      validate
    } = useValidateDomain();

    const {
      isModalOpen: isContractModalOpen,
      closeModal: closeContractModal
    } = useModal(false);

    const {
      isModalOpen: isRemoveDomainModal,
      openModal: openRemoveDomainModal,
      closeModal: closeRemoveDomainModal
    } = useModal(false);

    const removeDomain = ref();

    const onClose = () => {
      contract.value.domainName = contract.value.oldDomainName;
    };
    const currentEditedDomainIds = ref([]);

    const isAddedDomainLoading = computed(
      () => isCreateLoading.value || isUpdateLoading.value
    );

    const isPageLoading = ref(false);
    const applyDomain = async () => {
      closeContractModal();
      const hasError = validate(
        contract.value.domainName,
        domainList.value,
        contract.value.id
      );
      if (hasError) {
        return;
      }
      isPageLoading.value = true;
      await postDomain(
        userInfo.value.id,
        contract.value.id,
        contract.value.domainName,
        () => {
          contract.value.isDomainSubmitted = true;
        }
      );
      contract.value.oldDomainName = contract.value.domainName;
      isPageLoading.value = false;
    };

    return {
      removeDomain,
      isRemoveDomainModal,
      acl,
      workspaceSetDomainApprovalAcl,
      workspaceManageMailDomainAcl,
      workspaceApplyFeeChargingPlanAcl,
      workspace,
      contract,
      domainList,
      PAYMENT_TYPE,
      VALIDATION_MESSAGE,
      isContractModalOpen,
      closeContractModal,
      addDomain,
      removeDomainList,
      deleteDomain,
      closeRemoveDomainModal,
      openRemoveDomainModal,
      errorMessageIds,
      domainErrorMessages,
      validate,
      newDomainIds,
      // [承認切り替え] v1.0 スコープ外
      // toggleApproval: async (domainId, isApproval) => {
      //   await patchApproval(domainId, isApproval);
      //   toast({
      //     title: 'タイトル',
      //     message: `${isApproval ? '承認制' : '自動承認'}に変更しました`
      //   });
      // },
      changeDomain: async (domainId, domainName, oldDomainName) => {
        const hasError = validate(
          domainName,
          [...domainList.value.filter(d => d.id !== domainId), contract.value],
          domainId
        );
        if (hasError) {
          return;
        }

        currentEditedDomainIds.value = [
          ...currentEditedDomainIds.value,
          domainId
        ];
        try {
          const isNew = newDomainIds.value.includes(domainId);
          if (isNew) {
            await createDomain(domainName);
          } else {
            await updateDomain({ domainId, domainName });
          }
          if (isNew) {
            removeNewDomainId(domainId);
          }
          currentEditedDomainIds.value = currentEditedDomainIds.value.filter(
            id => id !== domainId
          );
          domainList.value = domainList.value.map(domain => {
            if (
              domain.oldDomainName === oldDomainName &&
              domain.domainName === domainName
            ) {
              domain.oldDomainName = domainName;
            }
            return domain;
          });
          successToast();
        } catch (e) {
          errorToast();
        }
      },
      applyDomain,
      isPageLoading,
      onClose,
      toast,
      isAddedDomainLoading,
      currentEditedDomainIds,
      AclRules
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';

.approval-switch {
  height: 32px;

  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    height: 32px;
    border-left: 1px solid var(--border-color);
  }

  padding-right: $spacing-base * 8;
  margin-right: $spacing-base * 8;
}
</style>
